import { ApiService } from "../helpers/api.service";
import { ref } from "vue";
import Swal from "sweetalert2";
import BrForm from "../helpers/forms/index"
import router from "../router";

export default function useProgram(formType, programa){

    const program_schema = ref({});
    const baseFormModifier = ref({});
    const table_schema = ref({});
    const sis_detail_table = ref([]);
    const data = ref([]);
    const loading = ref(true);
    const btnloading = ref(false);
    const isJsonGeneratedForm = ref(false);

    function setProgramForm(){
        loading.value = true;
        table_schema.value = programa.program_schema.sis_tabela
        program_schema.value = programa.program_schema
        data.value = programa.dados;
        isJsonGeneratedForm.value = programa.program_schema.isJsonGeneratedForm;
        loading.value = false;
        try{
            setBaseFormAndDetailTablesForm();
        }catch(e){
            Swal.fire({
                icon: "error",
                html: `Ocorreu um erro ao tentar criar o formulário base ou de tabela detalhe...<br>${e.message}`,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
        }
    }

    // get the table details of every column that has a id_tabela_detalhe != null
    // Each detail table will have a form modifier class to control your form inputs
    async function setBaseFormAndDetailTablesForm(){
        baseFormModifier.value = new BrForm(table_schema.value);
        baseFormModifier.value.setActivateDetailTable = setActivateDetailTable
        baseFormModifier.value.setRegisters(data.value)
        if(formType == 'edition' || formType == 'show'){ 
            baseFormModifier.value.setDataAlreadyExists(true);
            if(isJsonGeneratedForm.value){
                if(data
                    .value[programa.program_schema.originalProgramSchema.sis_tabela.nome_tabela]
                    .registros[0][table_schema.value.nome_tabela]
                ){
                    baseFormModifier.value.setInitialValuesFromObject(
                        data
                        .value[programa.program_schema.originalProgramSchema.sis_tabela.nome_tabela]
                        .registros[0][table_schema.value.nome_tabela]
                    )
                }
            }else{
                baseFormModifier.value.setInitialValuesFromObject(
                    data
                    .value[table_schema.value.nome_tabela].registros[0]
                )
            }
        }

        const mainTablePrimaryKeyName = table_schema.value.sis_campo_tabela.find(sct => sct.chave_primaria)?.nome || 'id';
        const sct = table_schema.value.sis_campo_tabela
        for(let i = 0; i < sct.length; i++){
            const col = sct[i]
        
            if(col.id_tabela_detalhe && (formType == 'edition' || formType == 'show')){
                sis_detail_table.value.push({ 
                    detailTableIdName: col.nome,
                    mainTablePrimaryKey: baseFormModifier.value.getValue(mainTablePrimaryKeyName),
                    id_campo_tabela: col.id_campo_tabela,
                    schema: col.dados_tabela_detalhe.sis_tabela,
                    title: col.titulo,
                    forms: [],
                    apagado: [],
                    permite_inclusao: col.permite_inclusao,
                    permite_alteracao: col.permite_alteracao,
                    permite_exclusao: col.permite_exclusao,
                })
            }
            if(col.id_tabela_detalhe && formType == 'creation'){
                sis_detail_table.value.push({ 
                    detailTableIdName: col.nome,
                    mainTablePrimaryKey: baseFormModifier.value.getValue(mainTablePrimaryKeyName),
                    id_campo_tabela: col.id_campo_tabela,
                    schema: col.dados_tabela_detalhe.sis_tabela,
                    title: col.titulo,
                    forms: [],
                    permite_inclusao: col.permite_inclusao,
                    permite_alteracao: col.permite_alteracao,
                    permite_exclusao: col.permite_exclusao,
                })
            }
        }

        if(formType == 'edition' || formType == 'show'){
            for(let i = 0; i < sis_detail_table.value.length; i++){
                await setDetailTablesForms(sis_detail_table.value[i]);
                setActivateDetailTable(0, sis_detail_table.value[i])
            }
        }else{
            for(let i=0; i<sis_detail_table.value.length; i++){
                sis_detail_table.value[i].isLoaded = true;
            }
        }
    }

    function setActivateDetailTable(start, detail_table){
        let stop = start + 20
        if(!detail_table.forms.length) return;
        if(stop > detail_table.forms.length - start) stop = detail_table.forms.length
        for(let i = start; i < stop; i++){
            detail_table.forms[i].setActive(true);
        }
    }

    async function setDetailTablesForms(detail_table){
        detail_table.isLoaded = false
        const registers = data.value[detail_table.schema.nome_tabela].registros
        for(let i = 0; i < registers.length; i++){
            const registro = registers[i]
            const _tdForm = new BrForm(detail_table.schema, false, 'show', false)
            _tdForm.setInitialValuesFromObject(registro)
            _tdForm.setDataAlreadyExists(true)
            detail_table.forms.push(_tdForm)
        }
        detail_table.shouldResetScroll = false
        detail_table.total_registers = registers.length
        detail_table.isLoaded = true
    }

    function setAllCreationStepState(bool){
        sis_detail_table.value.forEach(sdt => {
            sdt.forms.forEach(form => form.setInCreationStep(bool))
        })
    }

    function addNewDetailTableForm(id_tabela, checkCreationStep = true, callback = null){
        if(checkCreationStep){
            const stopAdd = checkIfHasDetailTableFormInCreationStep()
            if(stopAdd) return;
        }

        const index = sis_detail_table.value.findIndex(sdt => sdt.schema.id_tabela == id_tabela);
        const sdt = sis_detail_table.value[index]
        sdt.forms.unshift(new BrForm(sdt.schema, true, formType))
        // document.getElementsByTagName('html')[0].scrollTop = document.getElementsByTagName('html')[0].scrollHeight;

        const addedToIndex = 0
        if(callback){
            callback()
        }
        return addedToIndex;
    }

    //eslint-disable-next-line
    function removeDetailTableForm(id_tabela, formIndex, dataAlreadyExists = false){
        const index = sis_detail_table.value.findIndex(sdt => sdt.schema.id_tabela == id_tabela);
        const sdt = sis_detail_table.value[index]
        sdt.forms.splice(formIndex, 1)
        sdt.total_registers--
        setAllCreationStepState(false)
    }

    function checkIfHasDetailTableFormInCreationStep(){
        let founded = false;
        let stopAdd = false;
        sis_detail_table.value.forEach(sdt => {
            if(founded) return;
            const brForm = sdt.forms.find(form => form.getInCreationStep())
    
            if(brForm){
                const elements = document.querySelectorAll('.needs_validation_detail_table_' + brForm.table_schema.id_tabela)
                if(!elements) return;
                const notValid = Array.from(elements).filter(input => {
                    if(input.nodeName !== 'DIV' && !input.checkValidity()){
                        return input;
                    }
                });
                founded = true;
                
                if(notValid.length > 0){
                    checkFormValidity()
                    stopAdd = true;
                }else{
                    brForm.setInCreationStep(false)
                }
            }
        })
        return stopAdd;
    }

    function checkFormValidity(form_id){
        // const formElement = document.getElementsByClassName('needs-validation')[0]
        const formElement = document.getElementById(form_id)
        const inputs = Array.from(formElement.elements).filter(input => input.type !== "submit" && input.type !== "button" && input.type !== 'checkbox' && input.type !== 'radio');
        const radioInputs = Array.from(formElement.elements).filter(input => input.type == 'radio')
        const radioNamesArray = Array.from(new Set(radioInputs.map(input => input.name)))
        const radioArray = radioNamesArray.map(name => radioInputs.filter(input => input.name == name)[0])
        inputs.push(...radioArray)
        const checkboxInputs = Array.from(formElement.elements).filter(input => input.type == 'checkbox')
        const checkboxNamesArray = Array.from(new Set(checkboxInputs.map(input => input.name)))
        const checkboxArray = checkboxNamesArray.map(name => checkboxInputs.filter(input => input.name == name)[0])
        inputs.push(...checkboxArray)
        const incorrectInputs = inputs.filter(input => !input.checkValidity())
        const tableTitles = Array.from(new Set(incorrectInputs.map(input => input.getAttribute('input_table_title'))))

        if(incorrectInputs.length){
            formElement.classList.add('was-validated')

            incorrectInputs.forEach(input => {
                if(input.getAttribute('data-original-select-id')){
                    const select = document.getElementById(input.getAttribute('data-original-select-id'))
                    select.classList.add('is-invalid')
                }
            })

            let text = "Por favor, preencha corretamente os seguintes campos:<br>"
            tableTitles.forEach(table_title => {
                text += `<div class="text-start px-2"><span class="bg-light">${table_title}:</span><span class="mx-1"></span>`
                text += incorrectInputs.filter(input => input.getAttribute('input_table_title') == table_title)
                .map(input => `<span class="text-danger">${input.getAttribute('input_title')}</span>`).join(', ')
                text += `</div>`
            }),

            Swal.fire({
                icon: "error",
                html: text,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            })
            return false;
        }

        return true
    }

    async function handleFormSubmit(e, additional_data = [], function_route = null, returnToNavigator = true, shouldReturnData = false, third_party_body = null, onSuccess = false){
        const formElement = e.target;
        if(!checkFormValidity(formElement.id)) return false;
        
        setFormReadOnly(formElement, true)
        
        // container for every detail_table of parent table
        const detailTablesObject = {}

        sis_detail_table.value.forEach(detailTable => {
            const nonDeletedForms = detailTable.forms.filter(f => !f.deleted)
            detailTablesObject[detailTable.schema.nome_tabela] = {
                registros: nonDeletedForms.map(form => form.hasChanged ? form.getValuesDetailTable() : form.getOriginalValues())
            } 
        })

        const formValues = {
            ...baseFormModifier.value.getValues().dados,
            ...detailTablesObject
        }
        if(additional_data?.length){
            additional_data.forEach(additional_data => {
                formValues[additional_data.nome_tabela] = {
                    registros: [
                        ...additional_data.data
                    ]
                }
            })
        }
        if(programa.program_schema.isJsonGeneratedForm){
            const baseFormRegistros = data.value[programa.program_schema.originalProgramSchema.sis_tabela.nome_tabela].registros[0]
            formValues[programa.program_schema.originalProgramSchema.sis_tabela.nome_tabela] = {
                registros: [
                    baseFormRegistros
                ]
            }
        }
        
        const body = {
            id_programa: programa.program_schema.isJsonGeneratedForm ? programa.program_schema.originalProgramSchema.id_programa : program_schema.value.id_programa,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 1,
            pagina: 0,
            dados: formValues
        }

        if(formType == 'creation'){
            body.rota = programa.program_schema.isJsonGeneratedForm ? 
            (programa.program_schema.originalProgramSchema.rota_backend + programa.program_schema.originalProgramSchema.sis_funcionalidade.find(f => f.denominacao == 'Incluir')?.rota_backend)
            :
            (program_schema.value.rota_backend + program_schema.value.sis_funcionalidade.find(f => f.denominacao == 'Incluir').rota_backend)
        }else if(formType == 'edition'){
            body.id = programa.registro_id;
            body.rota = programa.program_schema.isJsonGeneratedForm ? 
            (programa.program_schema.originalProgramSchema.rota_backend + programa.program_schema.originalProgramSchema.sis_funcionalidade.find(f => f.denominacao == 'Editar').rota_backend)
            :
            (program_schema.value.rota_backend + program_schema.value.sis_funcionalidade.find(f => f.denominacao == 'Editar').rota_backend)
        }
        if(function_route){
            body.rota = function_route
        }
        if(third_party_body){
            body.id_programa = third_party_body.id_programa
            body.id = third_party_body.id
            body.rota = third_party_body.rota
        }

        btnloading.value = true;
        
        const apiResponse = await ApiService({
            method: 'POST',
            url: `/program`,
            data: body,
            headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
        })
        .then(async (response) => {
            const res = response.data;
            if(shouldReturnData) return res
            if(!res.status){
                Swal.fire({
                    icon: "error",
                    text: res.mensagem ? res.mensagem : res.erro,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });

                btnloading.value = false;
            }else{
                const dados = res.retorno.dados;
                Swal.fire({
                    icon: "success",
                    text: 'Salvo!',
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                if(onSuccess){
                    onSuccess()
                }
                if(formType == 'creation' && returnToNavigator){
                    if(['signin-basic', 'signin-with-email-basic'].includes(localStorage.getItem('lastAcessedRouteName'))){
                        router.replace({name: 'default'})
                    }else{
                        router.go(-1)
                    }
                }else if(formType == 'edition' && !programa.program_schema.isJsonGeneratedForm){
                    resetMainAndDetailTableForms(dados)
                }
                btnloading.value = false;
            }
        })
        .catch(error => {
            if(shouldReturnData) return {error: error}
            Swal.fire({
                icon: "error",
                text: error.message,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
            btnloading.value = false;
        })
        setFormReadOnly(formElement, false)
        formElement.classList.remove('was-validated');

        return apiResponse
    }

    function setFormReadOnly(formElement, bool){
        var elements = formElement.elements;
        for (var i = 0, len = elements.length; i < len; ++i) {
            elements[i].readOnly = bool;
        }
    }

    function resetMainAndDetailTableForms(dados){
        data.value = dados;
        for(let i=0; i<sis_detail_table.value.length; i++){
            const dt = sis_detail_table.value[i]
            dt.forms = []
            dados[dt.schema.nome_tabela].registros
            for(let j=0; j<dados[dt.schema.nome_tabela].registros.length; j++){
                const registro = dados[dt.schema.nome_tabela].registros[j]
                const _tdForm = new BrForm(dt.schema, false, 'show', false)
                _tdForm.setInitialValuesFromObject(registro)
                _tdForm.setDataAlreadyExists(true);
                dt.forms.push(_tdForm)
            }
            setActivateDetailTable(0, sis_detail_table.value[i])
            dt.shouldResetScroll = true
            dt.total_registers = dados[dt.schema.nome_tabela].registros.length
        }
    }

    return {
        setProgramForm,
        addNewDetailTableForm,
        removeDetailTableForm,
        handleFormSubmit,
        baseFormModifier,
        table_schema,
        sis_detail_table,
        loading,
        btnloading,
        resetMainAndDetailTableForms,
        setDetailTablesForms
    }
}