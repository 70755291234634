<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
export default {
    props: {
    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const password = ref('');
        const passwordInput = ref(null);
        const handleSubmit = () => {
            if (store.state.massActionsModals.passwordInputModalLoading) {
                return false;
            }
            store.state.massActionsModals.passwordInputModalonSave(password.value)
        }
        watch(() => store.state.massActionsModals.showPasswordInputModal, (value) => {
            show.value = value;
            password.value = '';
            passwordInput.value.attributes.type.value = 'password';
            if (value) {
                setTimeout(() => {
                    document.getElementById('tfa-massaction-digit1-input').focus()
                }, 400)
            }
        })
        watch(() => show.value, (value) => {
            if (!value) {
                // store.state.massActionsModals.passwordInputModalonCloseWithoutSave()
                passwordInput.value.attributes.type.value = 'password';
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showPasswordInputModal',
                        value: false
                    }
                ])

                if (document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                    document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })

        return {
            passwordInput,
            show,
            password,
            handleSubmit,
        }
    },
}
</script>

<template>
    <b-modal size="md" v-model="show" centered hideFooter no-close-on-esc no-close-on-backdrop and hide-header-close>
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card-body px-4">
                        <div class="text-center mt-2">
                            <h5 class="text-primary">Sua sessão expirou!</h5>
                            <p>Por favor, insira sua senha para continuar.</p>
                        </div>

                        <div class="p-2 mt-1">
                            <form @submit.prevent="handleSubmit">
                                <div class="mb-3">
                                    <label class="form-label" for="password-input1">
                                        Senha
                                    </label>
                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                        <input v-model="password" type="password" ref="passwordInput"
                                            class="form-control pe-5 password-input1" placeholder="Coloque sua senha">
                                        <button
                                            @click="passwordInput.attributes.type.value === 'password' ? passwordInput.attributes.type.value = 'text' : passwordInput.attributes.type.value = 'password'"
                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon1"
                                            type="button">
                                            <i class="ri-eye-fill align-middle" />
                                        </button>
                                    </div>
                                </div>

                                <div v-if="$store.state.massActionsModals.passwordInputModalLoading" class="mt-3 d-flex justify-content-center">
                                    <b-spinner variant="success"></b-spinner>
                                </div>
                                <div v-if="!$store.state.massActionsModals.passwordInputModalLoading" class="mt-4">
                                    <button class="btn btn-success w-100" type="submit">
                                        Continuar
                                    </button>
                                    <a class="btn btn-link w-100 mt-2" href="/logout">
                                        <i class="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                                        <span class="align-middle" data-key="t-logout">
                                          Sair
                                        </span>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>