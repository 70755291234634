<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    program_schema: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tipo_programa: {
      type: Number
    }
  },
  computed: {
    teste() {
    return this.$store.state.painelPrograma.expandedBI;
    }
  },
  methods: {
    setExpandedBI() {
      this.$store.commit('painelPrograma/setExpandedBI', true);
    }
  },
  data: () => ({
    showHelpModal: false
  }),
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box py-1 d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">{{ title }}</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item" v-for="(item, index) in items" :key="index" :class="{ active: item[0] }">
              <a :href="item.href" :class="item.active ? 'text-success' : ''">{{ item.text }}</a>
            </li>
          </ol>

          <button  @click="() => showHelpModal = true" type="button" class="btn btn-ghost-info btn-icon p-1 fs-20"
            title="Ajuda">
            <i class="ri-question-line align-bottom me-1"></i>
          </button>
          <button v-if="$props.tipo_programa == 7" @click="setExpandedBI" type="button" class="btn btn-ghost-info btn-icon p-1 fs-20"
            title="Expandir BI">
            <i class="bx bx-fullscreen"/>
          </button>
        </div>
      </div>
    </div>
  </div>

  <b-modal id="helpModal" fade scrollable centered size="xl" hide-header hide-footer v-model="showHelpModal">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="mt-4 markdown">
            <template v-if="$props.program_schema?.link_ajuda && showHelpModal">
              <div v-html="$props.program_schema.link_ajuda"></div>
            </template>
            <template v-else>
              <h6 class="text-center">Ainda não há uma documentação para este programa.</h6>
            </template>

            <div class="hstack gap-2 justify-content-center mt-5">
              <button @click="() => showHelpModal = false" type="button" class="btn btn-light"
                data-bs-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style @scoped>
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #ccc;
}

.markdown a {
  color: #08a88a;
  text-decoration: underline !important;
}
</style>