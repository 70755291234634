<script>
import DynamicFilterValueListSelect from './dynamicFilterValueListSelect.vue';
import VisualizarDetalhesContasAPagar from './visualizarDetalhesContasAPagar.vue';
import PasswordInputModal from './passwordInputModal.vue';
import TFAAuthenticator from './TFAAuthenticator.vue';
import UploadFileModal from './uploadFileModal.vue';
import OpenTicketModal from './openTicketModal.vue';
import UpdateSaldo from './updateSaldo.vue';

export default {
    components: {
        VisualizarDetalhesContasAPagar,
        DynamicFilterValueListSelect,
        PasswordInputModal,
        TFAAuthenticator,
        UploadFileModal,
        OpenTicketModal,
        UpdateSaldo,
    }
}
</script>

<template>
    <VisualizarDetalhesContasAPagar />
    <DynamicFilterValueListSelect />
    <PasswordInputModal />
    <TFAAuthenticator />
    <UploadFileModal />
    <OpenTicketModal />
    <UpdateSaldo />
</template>