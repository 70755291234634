<script>
import { watch, ref, computed } from 'vue';
import { useStore } from 'vuex'
import uploadFile from '@/components/file/upload-file.vue'
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import AzureHelper from '@/helpers/azure';

export default {
    setup(){
        const store = useStore()
        const show = ref(false)
        const files = ref([])
        const getDefaultBody = () => {
            return {
                id: null,
                id_programa: null,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                dados: null,
                rota: '',
            }
        }

        watch(() => store.state.massActionsModals.globalUploadFileModalShow, (value) => {
            show.value = value
        })
        watch(() => show.value, (value) => {
            if(!value){
                files.value = []
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'globalUploadFileModalShow',
                        value: false
                    }
                ])
            }
        })

        const uploadToAzure = async (response) => {                
            const documents = response.data.retorno.dados.sis_documento.registros
            const documentsToUploadAzure = []
            files.value.forEach(file => {
                const filteredDocuments = documents
                    .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                    .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                const document = filteredDocuments[0] ?? null
                if (document) {
                    document.file = file
                    documentsToUploadAzure.push(document)
                }
            })

            const azurehelper = new AzureHelper()
            await azurehelper.uploadDocuments(documentsToUploadAzure)
        }

        const save = async () => {
            store.commit('massActionsModals/setProperty', [
                {
                    key: 'globalUploadFileModalLoading',
                    value: true
                }
            ])
            const {
                id_empresa,
                id_programa,
                id_registro,
                nome_tabela,
                nome_chave_primaria,
                rota,
            } = store.state.massActionsModals.globalUploadFileModalProperties
            const documentsToUpload = files.value.map(file => {
                return {
                    nome_documento: file.name,
                    arquivo_base64: null,
                    tamanho: file.size,
                    descricao: file.descricao,
                    id_empresa: id_empresa
                }
            });
            const body = getDefaultBody()

            body.id = id_registro
            body.id_programa = id_programa
            body.rota = rota
            body.dados = {
                sis_documento: {
                    registros: [...documentsToUpload]
                },
                [nome_tabela]: {
                    registros: [
                        {[nome_chave_primaria]: id_registro}
                    ]
                }
            }

            const res = await sendFunctionRequest(body)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })

            store.state.massActionsModals.globalUploadFileModalOnSave(res)
            store.commit('massActionsModals/setProperty', [
                { key: 'globalUploadFileModalLoading', value: false }
            ])
            if (res.data?.status) {
                store.commit('massActionsModals/setProperty', [
                    { key: 'globalUploadFileModalShow', value: false }
                ])
                uploadToAzure(res)
            }
        }

        return {
            save,
            buttonText: computed(() => store.state.massActionsModals.globalUploadFileModalButtonText),
            loading: computed(() => store.state.massActionsModals.globalUploadFileModalLoading),
            show,
            files,
        }
    },
    components: {
        uploadFile
    }
}
</script>
<template>
    <b-modal id="globalUploadFileModal" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
        v-model="show">
        <uploadFile :updateFile="(selectedFiles) => files = selectedFiles" />
        <div class="d-grid gap-2">
            <div v-if="loading" class="text-center">
                <b-spinner variant="success" />
            </div>
            <b-button v-else class="btn btn-sm btn-success" @click="save">
                {{buttonText ?? 'Salvar'}}
            </b-button>
        </div>
    </b-modal>
</template>